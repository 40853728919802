<li
    class="eu-display_flex eu-cursor_pointer"
    [class.root-node]="isRoot()"
    [class.children-node]="!isRoot()"
    [class.active]="isActiveRoot()"
    (click)="handleNodeClick(node())"
    (mouseenter)="toggleHover()"
    (mouseleave)="toggleHover()"
>
    <div
        class="eu-display_flex eu-align-items_center trigger node-container"
        [class.expanded-node-container]="isExpanded()"
    >
        @if (node().iconUrl) {
            <button id="nav-button" class="emd-btn _type_text">
                <svg-icon
                    class="nav-icon"
                    [class.expanded-icon]="isExpanded()"
                    [src]="node().iconUrl"
                    [applyClass]="true"
                />
            </button>
        }

        <span
            class="emd-text node-label eu-cursor_pointer"
            [class.expanded-label]="isExpanded()"
            [class.child-label]="!isRoot()"
            style="flex-grow: 1"
            [class.active-child]="isActiveChild()"
            >{{ node().label }}</span
        >

        @if (isSimpleNavigation()) {
            <div
                class="menu-content emd-container"
                style="position: absolute"
                (mouseenter)="handleDropwDownMenuFocus()"
            >
                <ul
                    class="eu-display_flex eu-column"
                    [class.border-left]="isExpanded()"
                    style="gap: 0.75rem; width: 100%"
                >
                    @for (node of node().nodes; track $index) {
                        <li>
                            <app-navigation-node
                                [node]="node"
                                [isExpanded]="true"
                                [isRoot]="false"
                                (onHandleNavigation)="handleNavigate($event)"
                            />
                        </li>
                    }
                </ul>
            </div>
        }
    </div>

    @if (hasChildrenNodes() && isExpanded()) {
        <app-nav-toggle
            [isOpen]="showNodChildren()"
            [isHovered]="isActiveRoot() || isHovered() || isActiveChild()"
        />
    }
</li>

@if (isExpanded()) {
    <div class="dropdown-container" [class.root]="isRoot()" [class.hide]="!showNodChildren()">
        <ul
            class="eu-display_flex eu-column dropdown-content"
            [class.border-left]="showNodChildren()"
            [class.expanded]="isExpanded()"
            [class.show]="showNodChildren()"
            [class.hide]="!showNodChildren()"
            style="gap: 0.75rem; width: 100%"
        >
            @for (node of node().nodes; track $index) {
                <li class="drop-item">
                    <app-navigation-node
                        [node]="node"
                        [isExpanded]="isExpanded()"
                        [isRoot]="false"
                        (onHandleNavigation)="handleNavigate($event)"
                    />
                </li>
            }
        </ul>
    </div>
}
