import { Component, input, output, signal } from '@angular/core'

@Component({
    selector: 'app-nav-toggle',
    standalone: true,
    imports: [],
    templateUrl: './nav-toggle.component.html',
    styleUrl: './nav-toggle.component.scss',
})
export class NavToggleComponent {
    onToggleNode = output<boolean>()
    isOpen = input.required<boolean>()
    isHovered = input<boolean>(false)

    handleToggleNode() {
        this.onToggleNode.emit(this.isOpen())
    }
}
