import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { ToastrService } from 'ngx-toastr'
import { Subject } from 'rxjs'
import { environment } from '../../../../environments/environment'
import { HttpClientOptionsService } from '../../../legacy/authentication/services/http-client-options.service'
import { ErrorListArrayMessageParser } from '../../../legacy/core/services/error-list-array-message-parser.service'
import {
    GetProfileResponse,
    Profile,
    UpdateProfile,
    UpdateProfileResponse,
} from '../types/profile.types'

@Injectable({ providedIn: 'root' })
export class ProfileService {
    private profile$ = new Subject<Profile>()
    private userEmail!: string

    constructor(
        private httpClient: HttpClient,
        private httpClientOptions: HttpClientOptionsService,
        private toastr: ToastrService,
        private errorListMessageParser: ErrorListArrayMessageParser,
    ) {}

    get profile() {
        return this.profile$.asObservable()
    }

    getProfileData() {
        this.httpClient
            .get<GetProfileResponse>(
                `${environment.URL_BASE}/partnerhub/profile/get-profile`,
                this.httpClientOptions.getPartnerhubAuthorization(),
            )
            .subscribe({
                next: ({ data, success, errorList }) => {
                    if (success) {
                        this.profile$.next({
                            ...data,
                            isOwner: this.isOwner(data),
                        })
                        this.userEmail = data.user.email
                    } else
                        this.errorListMessageParser.showErrorListMessageOrDefault(
                            errorList,
                            'Falha não mapeada',
                        )
                },
                error: () => this.toastr.error('Erro ao obter Dados cadastrais'),
            })
    }

    isOwner(data: Profile) {
        return data.user.role_type === 'user-owner-partner'
    }

    updateProfile(body: UpdateProfile, callBack?: () => void) {
        return this.httpClient
            .post<UpdateProfileResponse>(
                `${environment.URL_BASE}/partnerhub/profile/update-profile`,
                body,
                this.httpClientOptions.getPartnerhubAuthorization(),
            )
            .subscribe({
                next: response => {
                    if (response.success) {
                        this.toastr.success('Dados cadastrais atualizados com sucesso!')
                        callBack?.()
                    } else
                        this.errorListMessageParser.showErrorListMessageOrDefault(
                            response.errorList,
                            'Falha não mapeada',
                        )
                },
                error: () => this.toastr.error('Erro ao atualizar Dados cadastrais'),
            })
    }

    requestTermsAndConditions(callBack?: () => void) {
        const payload = { email: this.userEmail }

        this.httpClient
            .post<any>(
                `${environment.URL_BASE}/partnerhub/documents/send-document-to-email`,
                payload,
                this.httpClientOptions.getPartnerhubAuthorization(),
            )
            .subscribe({
                next: response => {
                    if (response.success) {
                        callBack?.()
                        this.toastr.success(
                            'O arquivo foi enviado para o seu e-mail. Cheque sua caixa de entrada.',
                        )
                    } else
                        this.errorListMessageParser.showErrorListMessageOrDefault(
                            response.errorList,
                            'Falha não mapeada',
                        )
                },
                error: () => this.toastr.error('Erro ao processar requisição de envio de e-mail'),
            })
    }
}
