<nav class="eu-display_flex eu-column" [class.expanded]="isExpanded()" style="gap: 2rem">
    <header class="eu-display_flex nav-header" [class.eu-justify_center]="!isExpanded()">
        <img src="assets/icons/navigation/hub-logo.svg" />
        @if (isExpanded()) {
            <img
                [src]="
                    isDark()
                        ? 'assets/icons/navigation/partner-hub-dark.svg'
                        : 'assets/icons/navigation/partner-hub.svg'
                "
                width="103"
                height="61"
            />
        }
        <app-nav-mode-toggle
            [isSimpleNavigation]="isExpanded()"
            (onToggleNavMode)="toggleNavigationMode()"
        ></app-nav-mode-toggle>
    </header>

    <ul class="eu-display_flex eu-column" style="gap: 0.5rem">
        @for (navigationNode of navigationNodes; track $index) {
            @if (navigationNode.label !== 'Configurações') {
                <li
                    (mouseenter)="handleSimpleNavigationActiveNode(navigationNode)"
                    (mouseleave)="handleClearSimpleNavigationFocusedNode()"
                >
                    <app-navigation-node
                        [node]="navigationNode"
                        [isExpanded]="isExpanded()"
                        (onHandleNavigation)="handleNavigate($event)"
                    ></app-navigation-node>
                </li>
            }
        }
        <hr />

        <li (mouseenter)="handleNotificationsFocus()" (mouseleave)="hndleClearNotificationFocus()">
            <app-notification-manager [isExpanded]="isExpanded()"></app-notification-manager>
        </li>

        <li
            (mouseenter)="handleSimpleNavigationActiveNode(settingsNode)"
            (mouseleave)="handleClearSimpleNavigationFocusedNode()"
        >
            <app-navigation-node
                [node]="settingsNode"
                [isExpanded]="isExpanded()"
                (onHandleNavigation)="handleNavigate($event)"
            ></app-navigation-node>
        </li>
    </ul>
</nav>

<footer class="eu-display_flex eu-column eu-align_center" style="gap: 1rem">
    <app-theme-switcher [isExpanded]="isExpanded()"></app-theme-switcher>
    <app-user-avatar [isExpanded]="isExpanded()"></app-user-avatar>
</footer>
